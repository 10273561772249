<template>
  <div class='research'>
    <div class='container'>
      <div class='row'>
        <div class='col-md-12'>
          <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class='research-left' v-if="user != null">
<!--            <div class='research-left-title' v-if="language.short == 'mn'">{{ survey_obj.title }}</div>-->
<!--            <div class='research-left-title' v-else>{{ survey_obj.title_en }}</div>-->
<!--            <div class="">-->
<!--              <p v-if="language.short == 'mn'">{{survey_obj.description}}</p>-->
<!--              <p v-else>{{survey_obj.description_en}}</p>-->
<!--            </div>-->
            <survey :survey='survey' v-if="survey !== null" ></survey>
          </div>
          <div v-else class='research-left' style="text-align: center; padding: 80px 10px;display: block">
            <i class="ivu-icon ivu-icon-md-alert" style="font-size: 36px; color: #217BF4;"></i>
            <div style="margin: 20px 0">Нэвтэрч орсны дараа судалгаа бөглөх боломжтой !!!</div>
            <Button class="btn-color-primary" @click="gotoLogin()">&nbsp;&nbsp;&nbsp; Нэвтрэх &nbsp;&nbsp;&nbsp;</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as SurveyVue from 'survey-vue'
import * as widgets from 'surveyjs-widgets'
import { GET_SURVEY_BY_ID, SURVEY_SAVE } from '../graphql/queries'
import { mapGetters } from 'vuex'
var Survey = SurveyVue.Survey
Survey.cssType = 'bootstrap'
// widgets.icheck(SurveyVue);
widgets.select2(SurveyVue)
widgets.inputmask(SurveyVue)
widgets.jquerybarrating(SurveyVue)
widgets.jqueryuidatepicker(SurveyVue)
widgets.nouislider(SurveyVue)
widgets.select2tagbox(SurveyVue)
widgets.sortablejs(SurveyVue)
widgets.ckeditor(SurveyVue)
widgets.autocomplete(SurveyVue)
widgets.bootstrapslider(SurveyVue)
SurveyVue.Serializer.addProperty('question', 'tag:number')
var Traslation = {
  pagePrevText: "Өмнөх",
  pageNextText: "Дараах",
  completeText: "Дуусгах",
  previewText: "Харах",
  editText: "Засах",
  startSurveyText: "Эхлэх",
  otherItemText: "Бусад",
  noneItemText: "Хоосон",
  selectAllItemText: "Бүгдийг идэвхжүүлэх",
  progressText: "Нийт {1}-с {0}",
  panelDynamicProgressText: "Нийт {0}-с {1}",
  questionsProgressText: "Хариулсан {0}/{1} асуултаас",
  emptySurvey: "Судалгаанд харуулах асуулт байхгүй байна.",
  completingSurvey: "Судалгаанд хариулсанд баярлалаа!",
  completingSurveyBefore:
    "Our records show that you have already completed this survey.",
  loadingSurvey: "Судалгааг ачааллаж байна...",
  optionsCaption: "Сонгох...",
  value: "Утга",
  requiredError: "Заавал бөглөх",
  requiredErrorInPanel: "Response required: answer at least one question.",
  requiredInAllRowsError: "Response required: answer questions in all rows.",
  numericError: "Хариулт заавал тоо байх.",
  minError: "Хариулт үүнээс бага байх {0}",
  maxError: "The value should not be greater than {0}",
  textMinLength: "Please enter at least {0} character(s).",
  textMaxLength: "Please enter no more than {0} character(s).",
  textMinMaxLength:
    "Please enter at least {0} and no more than {1} characters.",
  minRowCountError: "Please fill in at least {0} row(s).",
  minSelectError: "Please select at least {0} variant(s).",
  maxSelectError: "Please select no more than {0} variant(s).",
  numericMinMax: "The '{0}' should be at least {1} and at most {2}",
  numericMin: "The '{0}' should be at least {1}",
  numericMax: "The '{0}' should be at most {1}",
  invalidEmail: "Хүчинтэй имэйл хаяг оруулна уу.",
  invalidExpression: "The expression: {0} should return 'true'.",
  urlRequestError: "The request returned error '{0}'. {1}",
  urlGetChoicesError:
    "The request returned empty data or the 'path' property is incorrect",
  exceedMaxSize: "Файлын хэмжээ хэтэрсэн байна {0}.",
  otherRequiredError: "Хариулт шаардлагатай тул: өөр утга оруулна уу.",
  uploadingFile:
    "Таны файл хуулагдаж байна. Түр хүлээгээд дахин оролдоно уу.",
  loadingFile: "Ачааллаж байна...",
  chooseFile: "Файлаа сонгоно уу...",
  noFileChosen: "Файл сонгоогүй байна",
  fileDragAreaPlaceholder: "Файлыг энд хуулах эсвэл доорх товчийг дарж файлыг ачаална уу.",
  confirmDelete: "Та устгахдаа итгэлтэй байна уу?",
  keyDuplicationError: "Энэ утга дахин давтагдашгүй байх.",
  addColumn: "Багана нэмэх",
  addRow: "Мөр нэмэх",
  removeRow: "Хасах",
  emptyRowsText: "Мөр бүртгээгүй байна.",
  addPanel: "Нэмэх",
  removePanel: "Хасах",
  choices_Item: "Шинэ",
  matrix_column: "Багана",
  matrix_row: "Мөр",
  multipletext_itemname: "Текст",
  savingData: "Үр дүнг хадгалж байна...",
  savingDataError: "Алдаа гарсан тул үр дүнг хадгалагдсангүй.",
  savingDataSuccess: "Үр дүнг амжилттай хадгаллаа!",
  saveAgainButton: "Дахин оролдоно уу.",
  timerMin: "мин",
  timerSec: "сек",
  timerSpentAll: "Та энэ хуудсанд {0}, нийтдээ {1} зарцуулсан байна.",
  timerSpentPage: "Та энэ хуудсанд {0} зарцуулсан байна.",
  timerSpentSurvey: "Та нийтдээ {0} зарцуулсан байна.",
  timerLimitAll:
    "Та энэ хуудсанд {1}-н {0}-г, нийт {3}-н {2}-г зарцуулсан байна.",
  timerLimitPage: "Та энэ хуудсанд {1}-н {0}-г зарцуулсан байна.",
  timerLimitSurvey: "Та нийт {1}-н {0}-г зарцуулсан байна.",
  cleanCaption: "Цэвэр",
  clearCaption: "Цэвэрлэх",
  signaturePlaceHolder: "Энд гарын үсгээ зурна уу.",
  chooseFileCaption: "Сонгох",
  removeFileCaption: "Устгах",
  booleanCheckedLabel: "Тийм",
  booleanUncheckedLabel: "Үгүй",
  confirmRemoveFile: "Энэ файлыг устгахдаа итгэлтэй байна уу: {0}?",
  confirmRemoveAllFiles: "Бүх файлыг устгахдаа итгэлтэй байна уу?",
  questionTitlePatternText: "Гарчиг",
  modalCancelButtonText: "Цуцлах",
  modalApplyButtonText: "Дуусгах",
  filteredTextPlaceholder: "Хайх утгаа бичнэ үү...",
}
export default {
  name: 'form_survey',
  props: ['id'],
  data () {
    return {
      ready: false,
      loading: true,
      survey: null,
      survey_obj: {},
      survey_data: {},
      disabledGroup: ['1'],
      checkSub: '1',
      turul: '2',
      textareaValue: '',
      inputValue: '',
      model1: '',
      selectMult: []
    }
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login");
    },
    onError() {
      this.$Notice.error({
        title: this.$t("error"),
        desc: this.$t("error_occurred_submitting")
      });
    },
    getSurveyById () {
      this.loading = true
      this.$apollo.query({
        query: GET_SURVEY_BY_ID,
        variables: { surveyId: this.id.toString() }
      }).then(({ data }) => {
        this.survey_obj = data.survey[0]
        // this.survey_data = JSON.parse(data.survey[0].survey_data)
        this.loading = false
        SurveyVue.surveyLocalization.locales.mn = Traslation;
        var model = new SurveyVue.Model(JSON.parse(data.survey[0].survey_data));

        model.locale = 'mn';
        this.survey = model;

        this.survey.onComplete.add((result) => {
              this.$apollo.mutate({
                mutation: SURVEY_SAVE,
                variables: {
                  user_id: this.user.id, survey_id: parseInt(this.id), result:JSON.stringify(result.data)
                }
              }).then(({data}) => {
                this.$Notice.success({
                  title: "Амжилттай хадгалагдлаа",
                  desc: "Судаллагаа амжилттай илгээгдлээ.",
                });
              });
            // console.log("result.data >>> ");
            // console.log(result.data);
            // console.log(JSON.stringify(result.data));
          })

      })
    },
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      'user',
      'userToken',
      // ...
    ]),
  },
  mounted () {
    this.getSurveyById();
  }
}
</script>

<style scoped>

</style>
