<template>
  <div class='research'>
    <div class='container'>
      <div class='row'>
        <div class='col-md-12'>
          <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class='research-left'>
<!--            <div class='research-left-title'>{{ survey_obj.title }}</div>-->
            <survey :survey='survey'></survey>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as SurveyVue from 'survey-vue'
import { GET_SURVEY_RESULT_BY_ID, GET_SURVEY_BY_ID} from '../graphql/queries'
import { mapGetters } from 'vuex'
import * as SurveyKnockout from 'survey-vue'
var Survey = SurveyVue.Survey
Survey.cssType = 'bootstrap'


var Traslation = {
  pagePrevText: "Өмнөх",
  pageNextText: "Дараах",
  completeText: "Дуусгах",
  previewText: "Харах",
  editText: "Засах",
  startSurveyText: "Эхлэх",
  otherItemText: "Бусад",
  noneItemText: "Хоосон",
  selectAllItemText: "Бүгдийг идэвхжүүлэх",
  progressText: "Нийт {1}-с {0}",
  panelDynamicProgressText: "Нийт {0}-с {1}",
  questionsProgressText: "Хариулсан {0}/{1} асуултаас",
  emptySurvey: "Судалгаанд харуулах асуулт байхгүй байна.",
  completingSurvey: "Судалгаанд хариулсанд баярлалаа!",
  completingSurveyBefore:
    "Our records show that you have already completed this survey.",
  loadingSurvey: "Судалгааг ачааллаж байна...",
  optionsCaption: "Сонгох...",
  value: "Утга",
  requiredError: "Заавал бөглөх",
  requiredErrorInPanel: "Response required: answer at least one question.",
  requiredInAllRowsError: "Response required: answer questions in all rows.",
  numericError: "Хариулт заавал тоо байх.",
  minError: "Хариулт үүнээс бага байх {0}",
  maxError: "The value should not be greater than {0}",
  textMinLength: "Please enter at least {0} character(s).",
  textMaxLength: "Please enter no more than {0} character(s).",
  textMinMaxLength:
    "Please enter at least {0} and no more than {1} characters.",
  minRowCountError: "Please fill in at least {0} row(s).",
  minSelectError: "Please select at least {0} variant(s).",
  maxSelectError: "Please select no more than {0} variant(s).",
  numericMinMax: "The '{0}' should be at least {1} and at most {2}",
  numericMin: "The '{0}' should be at least {1}",
  numericMax: "The '{0}' should be at most {1}",
  invalidEmail: "Хүчинтэй имэйл хаяг оруулна уу.",
  invalidExpression: "The expression: {0} should return 'true'.",
  urlRequestError: "The request returned error '{0}'. {1}",
  urlGetChoicesError:
    "The request returned empty data or the 'path' property is incorrect",
  exceedMaxSize: "Файлын хэмжээ хэтэрсэн байна {0}.",
  otherRequiredError: "Хариулт шаардлагатай тул: өөр утга оруулна уу.",
  uploadingFile:
    "Таны файл хуулагдаж байна. Түр хүлээгээд дахин оролдоно уу.",
  loadingFile: "Ачааллаж байна...",
  chooseFile: "Файлаа сонгоно уу...",
  noFileChosen: "Файл сонгоогүй байна",
  fileDragAreaPlaceholder: "Файлыг энд хуулах эсвэл доорх товчийг дарж файлыг ачаална уу.",
  confirmDelete: "Та устгахдаа итгэлтэй байна уу?",
  keyDuplicationError: "Энэ утга дахин давтагдашгүй байх.",
  addColumn: "Багана нэмэх",
  addRow: "Мөр нэмэх",
  removeRow: "Хасах",
  emptyRowsText: "Мөр бүртгээгүй байна.",
  addPanel: "Нэмэх",
  removePanel: "Хасах",
  choices_Item: "Шинэ",
  matrix_column: "Багана",
  matrix_row: "Мөр",
  multipletext_itemname: "Текст",
  savingData: "Үр дүнг хадгалж байна...",
  savingDataError: "Алдаа гарсан тул үр дүнг хадгалагдсангүй.",
  savingDataSuccess: "Үр дүнг амжилттай хадгаллаа!",
  saveAgainButton: "Дахин оролдоно уу.",
  timerMin: "мин",
  timerSec: "сек",
  timerSpentAll: "Та энэ хуудсанд {0}, нийтдээ {1} зарцуулсан байна.",
  timerSpentPage: "Та энэ хуудсанд {0} зарцуулсан байна.",
  timerSpentSurvey: "Та нийтдээ {0} зарцуулсан байна.",
  timerLimitAll:
    "Та энэ хуудсанд {1}-н {0}-г, нийт {3}-н {2}-г зарцуулсан байна.",
  timerLimitPage: "Та энэ хуудсанд {1}-н {0}-г зарцуулсан байна.",
  timerLimitSurvey: "Та нийт {1}-н {0}-г зарцуулсан байна.",
  cleanCaption: "Цэвэр",
  clearCaption: "Цэвэрлэх",
  signaturePlaceHolder: "Энд гарын үсгээ зурна уу.",
  chooseFileCaption: "Сонгох",
  removeFileCaption: "Устгах",
  booleanCheckedLabel: "Тийм",
  booleanUncheckedLabel: "Үгүй",
  confirmRemoveFile: "Энэ файлыг устгахдаа итгэлтэй байна уу: {0}?",
  confirmRemoveAllFiles: "Бүх файлыг устгахдаа итгэлтэй байна уу?",
  questionTitlePatternText: "Гарчиг",
  modalCancelButtonText: "Цуцлах",
  modalApplyButtonText: "Дуусгах",
  filteredTextPlaceholder: "Хайх утгаа бичнэ үү...",
}
export default {
  name: 'survey-view',
  props: ['id'],
  data () {
    return {
      ready: false,
      loading: true,
      result: {},
      survey_obj: {},
      survey_data: {},
      disabledGroup: ['1'],
      checkSub: '1',
      turul: '2',
      textareaValue: '',
      inputValue: '',
      model1: '',
      selectMult: [],
      survey: {},
    }
  },
  methods: {
    // surveyResultParse(id, results) {
    //   var self = this;
    //   self.id = id;
    //   self.results = results;
    //   self.jsonResultsValue = null;
    //   self.getJsonResults = function() {
    //     if (!self.jsonResultsValue && self.results) {
    //       self.jsonResultsValue = JSON.parse(self.results);
    //     }
    //     return self.jsonResultsValue;
    //   }
    // },
    renderCorrectAnswer(q) {
      if (!q)
        return;
      const isCorrect = this.isAnswerCorrect(q);
      if (!q.prevTitle) {
        q.prevTitle = q.title;
      }
      if (isCorrect === undefined) {
        q.title = q.prevTitle;
      }
      q.title = q.prevTitle;
    },
    isAnswerCorrect(q) {
      const right = q.correctAnswer;
      if (!right || q.isEmpty())
        return undefined;
      var left = q.value;
      if (!Array.isArray(right))
        return right == left;
      if (!Array.isArray(left))
        left = [left];
      for (var i = 0; i < left.length; i++) {
        if (right.indexOf(left[i]) < 0)
          return false;
      }
      return true;
    },
    getSurveyresultData() {
      this.loading = true
      this.$apollo.query({
        query: GET_SURVEY_BY_ID,
        variables: {surveyId: this.id.toString(), }
      }).then(({data}) => {
        let surveyJsonParseData = JSON.parse(data.survey[0]['survey_data'])
        this.survey = new SurveyKnockout.Model(surveyJsonParseData);
        SurveyVue.surveyLocalization.locales.mn = Traslation;
        this.survey.locale = 'mn';
        if(surveyJsonParseData){
          this.$apollo.query({ query: GET_SURVEY_RESULT_BY_ID,
            variables: {
              s_id: this.id.toString(),
              user_id: this.user.id,
            }
          }).then(({data}) => {
            let surveyResultJsonParseData = [];
            for(let i = 0; i < data.survey_result.length; i++){
              surveyResultJsonParseData.push(JSON.parse(data.survey_result[i]["result"]));
              // let item = data.survey_result[i];
              // surveyResultJsonParseData.push(new this.surveyResultParse(i + 1, item.result, item.id));
              // document.getElementById("surveyResult").innerHTML = "survey result data: " + item.result;
            }
            this.results = surveyResultJsonParseData;

            console.log("this.results");
            console.log(this.results);

            this.survey.mode = 'display';
            this.survey.data = this.results[0];
            this.survey.getAllQuestions().forEach(q => this.renderCorrectAnswer(q));

            this.loading = false;
          })
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      'user',
      // ...
    ]),

  },
  mounted () {
    this.getSurveyresultData()
  },
  // setSurveyTheme() {
  //   // Custom theme.
  //   let defaultThemeColors = SurveyVue.StylesManager.ThemeColors["default"];
  //   defaultThemeColors["$main-color"] = "#6ce5b9";
  //   defaultThemeColors["$main-hover-color"] = "#64b487";
  //   defaultThemeColors["$text-color"] = "#4a4a4a";
  //   defaultThemeColors["$header-color"] = "#6ce5b9";
  //   defaultThemeColors["$header-background-color"] = "#4a4a4a";
  //   defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
  //   SurveyVue.StylesManager.applyTheme();
  //   // SurveyVue.StylesManager.applyTheme("default");
  //   SurveyVue.surveyLocalization.currentLocale = "es";
  // }
}
</script>

<style scoped>

</style>
